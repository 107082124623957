// extracted by mini-css-extract-plugin
export var adBanner = "index-module--adBanner--CLlz4";
export var adBannerParent = "index-module--adBannerParent--HtCUR";
export var appButtons = "index-module--appButtons--LrIZF";
export var cookieConsent = "index-module--cookieConsent--N6ADB";
export var google = "index-module--google--2q6Ob";
export var groupBadge = "index-module--groupBadge--ELxYw";
export var groupMain = "index-module--groupMain--yiydK";
export var ms = "index-module--ms--6aT42";
export var selectBox = "index-module--selectBox--Tlx7V";
export var shortcut = "index-module--shortcut--+VEtg";
export var shortcut_remove = "index-module--shortcut_remove---YUMw";
export var welcomeContainer = "index-module--welcomeContainer--Q0pqj";
export var welcomeText = "index-module--welcomeText--W5Tyr";
export var yellow = "index-module--yellow--nF4ie";