import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import SEO from "../components/seo"
import Select, { components } from 'react-select'
import Layout from "../components/layout"
import * as styles from "./index.module.css"
import jogging from "../images/jogging.png"
import lightbulb from "../images/lightbulb.svg"
import news from "../images/news.svg"
import googleplay from "../images/fi_badge_web_generic.png"
import msstore from "../images/Finnish_get-it-from-MS.png"
import loadable from '@loadable/component'

const PWAPrompt = loadable(() => import("react-ios-pwa-prompt"));

var formatGroupLabel = data => (
  <div className={styles.groupMain}>
    <span>{data.label}</span>
    <span className={styles.groupBadge}>{data.options.length}</span>
  </div>
);

const customStyles = {
  control: base => ({
    ...base,
    height: 60,
    minHeight: 45,
    fontSize: 18,
    marginTop: 10
  })
};

const browser = typeof window !== 'undefined';

const IndexPage = () => {
  const [appInterface, setAppInterface] = useState(true);
  const [groupedOptions, setGroupedOptions] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [ownRestaurant, setOwnRestaurant] = useState(browser ? (localStorage.getItem('ownRestaurant') ? localStorage.getItem('ownRestaurant').split(';#') : []) : [])

  const removeAllOwnRestaurants = () => {
    // ask confirmation for removal first
    if (window.confirm('Haluatko varmasti poistaa kaikki pikalinkit?')) {
      localStorage.removeItem('ownRestaurant');
      setOwnRestaurant([]);
    }
  }

  const gotoOwnRestaurant = (id) => {
    if (id.startsWith('d_')) {
      window.location.href = `/dmenu/${id.substring(2)}/`;
    } else {
      window.location.href = `/menu/${id}/`;
    }
  }

  if (browser && !window.location.href.includes('/?stay')) {
    if (ownRestaurant.length === 1) {
      gotoOwnRestaurant(ownRestaurant[0].split('|')[0]);
    }
  }

  useEffect(() => {
    try {
      if (browser) {
        const adsbygoogle = window.adsbygoogle || [];
        adsbygoogle.push({});

        getGroupedOptions();
      }
    } catch (e) {
      console.error(e);
    }

    if (
      !(
        navigator.standalone ||
        window.matchMedia("(display-mode: standalone)").matches
      )
    ) {
      setAppInterface(false);
    }
  }, [])

  const data = useStaticQuery(graphql`
    query Restaurants {
      allAzureJson(filter: {WeekMenu: {elemMatch: {RestaurantId: {ne: null}}}}) {
        nodes {
          WeekMenu {
            RestaurantId
            RestaurantName
            CityName
          }
        }
      }
    }
    `)

  async function getGroupedOptions() {
    let tempGo = [];

    // cities
    data.allAzureJson.nodes.forEach(node => {
      if (tempGo.findIndex(x => x.label === node.WeekMenu[0].CityName) === -1) {
        tempGo.push(
          {
            label: node.WeekMenu[0].CityName
          });
      }
    });

    tempGo.sort((a, b) => a.label.localeCompare(b.label, 'fi'));

    tempGo.forEach(city => {
      var restaurants = [];

      data.allAzureJson.nodes.filter(c => c.WeekMenu[0].CityName === city.label)
        .forEach(node => {
          let restaurantShortName = node.WeekMenu[0].RestaurantName.replace(`${node.WeekMenu[0].CityName} - `, '');

          if (restaurants.findIndex(x => x.label === restaurantShortName) === -1) {
            restaurants.push({
              "label": restaurantShortName,
              "value": node.WeekMenu[0].RestaurantId.indexOf('dmenu_') === 0 ? node.WeekMenu[0].RestaurantId.replace('dmenu_', 'dmenu/') : `/menu/${node.WeekMenu[0].RestaurantId}/`
            });
          }
        });

      city.options = restaurants.sort((a, b) => a.label.localeCompare(b.label, 'fi'));
    });

    setGroupedOptions(tempGo);
    setIsLoading(false);
  }

  const handleKeyDown = (evt) => {
    switch (evt.key) {
      case "Home": //evt.preventDefault();
        if (evt.shiftKey) evt.target.selectionStart = 0;
        else evt.target.setSelectionRange(0, 0);
        break;
      case "End": //evt.preventDefault();
        const len = evt.target.value.length;
        if (evt.shiftKey) evt.target.selectionEnd = len;
        else evt.target.setSelectionRange(len, len);
        break;
      default:
        break;
    }
  };

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        Ei löydy, etsi kaupungin nimellä tai <Link to='/palaute/'>lähetä palautetta</Link>.
      </components.NoOptionsMessage>
    );
  };

  return (
    <Layout>
      <SEO title="Koulujen ruokalistat nopeasti" description="Koulusi lounaslistat nopeasti ja helposti" />

      <Select
        className={styles.selectBox}
        placeholder="Hae koulun tai kaupungin nimellä..."
        options={groupedOptions}
        formatGroupLabel={formatGroupLabel}
        styles={customStyles}
        onChange={(value, action) => window.location.href = `${value.value}`}
        openMenuOnClick={false}
        onKeyDown={handleKeyDown}
        components={{ NoOptionsMessage }}
        isLoading={isLoading}
        isDisabled={isLoading}
      />

      {ownRestaurant && ownRestaurant.length > 0 ?
        <>
          {ownRestaurant.map((r, idx) => {
            let rArr = r.split('|');
            return (
              <button key={idx} className={styles.shortcut} onClick={() => gotoOwnRestaurant(rArr[0])}><img src={jogging} alt='Pikalinkki' />{rArr[1]}</button>);
          })}
          <button className={styles.shortcut_remove} onClick={removeAllOwnRestaurants} title="Poista kaikki pikalinkit">X</button>
        </>
        : <></>
      }

      <div className={styles.welcomeContainer}>
        <div className={`${styles.welcomeText} ${styles.yellow}`}>
          <h3><img src={lightbulb} alt='Hehkulamppu' />Tiesitkö?</h3>
          <strong>Vinkkejä</strong><br />
          <ul>            
            <li>Voit peukuttaa ruokia, edellisen kuun tulokset löydät <Link to="/tulokset/">täältä</Link>.</li>
            <li>Testaa taitosi hauskoissa <Link to='/pelit/'>peleissä</Link>.</li>
            <li>Pääset etusivulle yläreunan logosta.</li>
            <li>Voit valita useamman suosikkikoulun. Jos valitset vain yhden, sinut ohjataan suoraan sen koulun ruokalistasivulle.</li>
          </ul>
          <p>
            <strong>Voit tallentaa pikalinkin suoraan viikon ruokalistaan.</strong><br />
            Vaikkapa Porvoon tämän viikon ruokalista on osoitteessa <a href="/menu/porvoo_koulut/" target="_blank" rel="noreferrer">https://kouluruoka.fi/menu/porvoo_koulut</a>.
          </p>
        </div>
        <div className={styles.welcomeText}>
          <h3><img src={news} alt='Puhekupla' />Uutiset</h3>          
          <p>
            <strong>14.11.2024</strong><br />
            Testaa nokkeluutesi uudessa <Link to="/pelit/">sanapelissä</Link>. 🧩
          </p>
          <p>
            <strong>13.11.2024</strong><br />
            Ruokalistojen osoitteita korjattu ja välipalalistoja lisätty monelle koululle. 🧙‍♂️
          </p>
          <p>
            <strong>31.10.2024</strong><br />
            Lisätty etusivulle pikalinkkien poistomahdollisuus. 🚀
          </p>
          <p>
            <strong>29.12.2022</strong><br />
            Kaikki Suomen kunnat tuotu palveluun 🥳 <br />Ihan kaikista ei ruokalistaa vielä löydy, mutta vainukoiramme jatkavat etsimistä.
          </p>
        </div>
      </div>

      <div id='adbanner' className={styles.adBannerParent} key="adbanner">
        <ins className={[styles.adBanner, 'adsbygoogle'].join(' ')}
          data-ad-client="ca-pub-2668838512868610"
          data-ad-slot="5745330552"
          data-ad-format="horizontal,auto"
          data-full-width-responsive="true"></ins>
      </div>

      {!appInterface ?
        <div className={styles.appButtons}>
          <a href={`https://play.google.com/store/apps/details?id=fi.kouluruoka&utm_source=${appInterface ? 'app' : 'web'}&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1`} target="_blank" rel="noreferrer">
            <img alt='Get it on Google Play' src={googleplay} className={styles.google} />
          </a>
          <a href='https://www.microsoft.com/store/apps/9nqf3ldnxms9?cid=storebadge&ocid=badge' target='_blank' rel="noreferrer"><img src={msstore} alt='Get it on Microsoft Store' className={styles.ms} /></a>
          <br />
        </div> : ''}

      <PWAPrompt
        copyTitle='Lisää aloitusnäyttöön'
        copyBody='Voit sen jälkeen käynnistää sovelluksen aloitusvalikosta.'
        copyShareButtonLabel='1) Napauta Jaa-kuvaketta'
        copyAddHomeButtonLabel='2) Napauta "Lisää aloitusnäyttöön"'
        copyClosePrompt='Sulje'
        debug={false} />

    </Layout>
  )
};

export default IndexPage
